import React, { useEffect } from "react";

const AloompaEmbed = ({ item }) => {

   useEffect(() => {
    // Inject the external script
    const existingScript = document.querySelector(
        'script[src="https://embedded.aloompa.com/static/js/embed-v1.js"]'
    );

    if (!existingScript) {
        const script = document.createElement("script");
        script.src = "https://embedded.aloompa.com/static/js/embed-v1.js";
        script.type = "text/javascript";
        document.body.appendChild(script);
    }

    // Polling to check if aloompaEmbed is available
    const interval = setInterval(() => {
        if (window.aloompaEmbed) {
            window.aloompaEmbed.init();
            clearInterval(interval); // Stop polling once initialized
        }
    }, 100); // Poll every 100ms

    return () => clearInterval(interval); // Cleanup the interval on unmount

   }, []);

   return (
     <aloompa-embed
       data-app={item.get('field_app_id')}
       data-subApp={item.get('field_sub_app_id')}
       data-embed={item.get('field_embed_id')}
       embed-locale="en"
     ></aloompa-embed>

   );

};

export default AloompaEmbed;