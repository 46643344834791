import React, { Component } from 'react';
import OfferingCard from '../../components/content/offering/OfferingCard/OfferingCard';
import Include from '../../drupal/Include';
import Filter from '../../components/ui/Filter/Filter';
import { IonIcon } from '@ionic/react';
import { search, close } from 'ionicons/icons';
import SuperShopperPagePlaceholder from './SuperShopperPagePlaceholder';
import Selector from '../../components/ui/Selector/Selector/Selector';

import './SuperShopperPage.css';

export default class SuperShopperPage extends Component {

    state = {
        allOfferings: [],
        filteredOfferings: [],
        filteredHappenings: [],
        allHappenings: [],
        allVenues: [],
        allSubjects: [],
        allGenres: [],
        allCountries: [],
        filteredVenues: [],
        filteredSubjects: [],
        filteredGenres: [],
        filteredCountries: [],
        allLanguages: [],
        filteredLanguages: [],
        filters: {
            venue: null,
            subject: null,
            genre: null,
            country: null,
            availability: null,
            exclusive: null,
            qas: false,
            subtitles: false,
            language: null,
            cc: false,
            title: '',
            sort: 'asc'
        },
        loading: true
    };

    componentDidMount = async () => {

        const allHappenings = await this.getAllHappenings();

        const allOfferings = this.parseOfferings(allHappenings);

        const venues = this.getVenues(allHappenings);
        const subjects = this.getSubjects(allOfferings);
        const genres = this.getGenres(allOfferings);
        const countries = this.getCountries(allOfferings);
        const languages = this.getLanguages(allOfferings);

        this.setState({
            allHappenings: allHappenings,
            filteredHappenings: allHappenings,
            allOfferings: allOfferings,
            filteredOfferings: allOfferings,
            allVenues: venues,
            filteredVenues: venues,
            allSubjects: subjects,
            filteredSubjects: subjects,
            allGenres: genres,
            filteredGenres: genres,
            allCountries: countries,
            filteredCountries: countries,
            allLanguages: languages,
            filteredLanguages: languages,
            loading: false
        });

        const query = new URLSearchParams(window.location.search);
        if(query.get('venue') ||
            query.get('subject') ||
            query.get('genre') ||
            query.get('country') ||
            query.get('availability') ||
            query.get('exclusive') ||
            query.get('title') ||
            query.get('language') ||
            query.get('qa') ||
            query.get('subtitles') ||
            query.get('cc')
        ) {
            const filters = {
                venue: query.get('venue') ? query.get('venue') : null,
                subject: query.get('subject') ? query.get('subject') : null,
                genre: query.get('genre') ? query.get('genre') : null,
                country: query.get('country') ? query.get('country') : null,
                availability: query.get('availability') ? query.get('availability') : null,
                exclusive: query.get('exclusive') ? query.get('exclusive') : null,
                language: query.get('language') ? query.get('language') : null,
                qas: query.get('qa') ? query.get('qa') : false,
                subtitles: query.get('subtitles') ? query.get('subtitles') : false,
                cc: query.get('cc') ? query.get('cc') : false,
                title: query.get('title') ? query.get('title') : null,
                sort: 'asc'
            };

            this.refilter(filters);
        }
    }

    render = () => {

        if(this.state.loading) {
            return (
                <SuperShopperPagePlaceholder />
            );
        }

        let offerings = [];
        let offeringLinks = [];

        for(const offering of this.state.filteredOfferings) {

            if(offering instanceof Include) {
                const offeringHappenings = this.getOfferingHappenings(offering.get('id'));
                offerings.push(<OfferingCard key={offering.get('id')} entity={offering} api={this.props.api} happenings={offeringHappenings} />);

                const title = offering.get('title');
                let url = offering.get('field_path');
                const featureFilm = offering.get('field_feature_film');
                if(featureFilm instanceof Include) {
                    url = featureFilm.get('field_path');
                }
                offeringLinks.push(
                    {
                        url: url,
                        title: title
                    }
                );
            }
        }

        if(offerings.length === 0) {
            offerings = (
                <h3>We're sorry, but there are no films that match your current filters.</h3>
            )
        }

        let searchIcon = close;
        if(this.state.filters.title === '' || this.state.filters.title === null) {
            searchIcon = search;
        }

        const availabilityOptions = [
            {
                id: 'purchase',
                label: 'Tickets Available'
            },
            {
                id: 'watch-now',
                label: 'Available to Watch Now'
            }
        ];

        const exclusiveOptions = [
            {
                id: 'person',
                label: 'In Theater Exclusive'
            },
            // {
            //     id: 'virtual',
            //     label: 'Streaming Exclusive'
            // },
            {
                id: 'both',
                label: 'In Theater & Streaming'
            },
        ];

        return (
            <div className="super-shopper-page container">
                <h1>Purchase Your Tickets Below</h1>
                <div className="filter-wrapper">
                    <h3>Filter your results</h3>
                    <div className="filters d-md-flex align-items-center">
                        <Filter key="genreFilter" filterName="genre" filterLabel="Type" filterClasses="flex-shrink-1" items={this.state.filteredGenres} handleChange={this.handleSelect} value={this.state.filters.genre} />

                        <Filter key="countryFilter" filterName="country" filterLabel="Country" filterClasses="flex-shrink-1" items={this.state.filteredCountries} handleChange={this.handleSelect} value={this.state.filters.country} />

                        <Filter key="subjectFilter" filterName="subject" filterLabel="Subject" filterClasses="flex-shrink-1" items={this.state.filteredSubjects} handleChange={this.handleSelect} value={this.state.filters.subject} />

                        <Filter key="availabilityFilter" filterName="availability" filterLabel="Availability" filterClasses="flex-shrink-1" items={availabilityOptions} handleChange={this.handleSelect} value={this.state.filters.availability} />

                        <Filter key="exclusiveFilter" filterName="exclusive" filterLabel="How to Watch..." filterClasses="flex-shrink-1" items={exclusiveOptions} handleChange={this.handleSelect} value={this.state.filters.exclusive} />


                    </div>
                    <div className="filters d-md-flex align-items-center">

                        <Filter key="languageFilter" filterName="language" filterLabel="Language" filterClasses="flex-shrink-1" items={this.state.filteredLanguages} handleChange={this.handleSelect} value={this.state.filters.language} />

                        <Filter key="subtitleFilter" filterName="subtitles" filterLabel="Subtitles" filterClasses="flex-shrink-1" handleChange={this.handleBoolean} value={this.state.filters.subtitles} type="boolean" />

                        <Filter key="qaFilter" filterName="qas" filterLabel="Q&amp;A" filterClasses="flex-shrink-1" handleChange={this.handleBoolean} value={this.state.filters.qas} type="boolean" />

                        <Filter key="ccFilter" filterName="cc" filterLabel="Closed Captions" filterClasses="flex-shrink-1" handleChange={this.handleBoolean} value={this.state.filters.cc} type="boolean" />

                    </div>
                    <div className="clear-filters" onClick={this.clearFilters} role="button">Clear all filters</div>
                </div>

                <div className="controls row">
                    <div className="title-search-wrapper col">
                        <input placeholder="Search by title" type="text" name="title" value={this.state.filters.title} onChange={this.handleSelect} className="title-search" />
                        <span className="icon"><IonIcon icon={searchIcon} onClick={this.clearSearch} role="button" /></span>
                    </div>

                    <div className="offering-selector col">
                        <Selector items={offeringLinks} />
                    </div>

                    <div className="sort-wrapper col">
                        Sort list by: <select name="sort" onChange={this.handleSelect}>
                            <option value="asc">Title: A to Z</option>
                            <option value="desc">Title: Z to A</option>
                        </select>
                    </div>
                </div>

                <div className="super-shopper-page padded row">
                    <div className="col-12 search-results">
                        {offerings}
                    </div>
                </div>
            </div>
        );

    }

    handleSelect = (e) => {
        let filters = this.state.filters;

        if(e.currentTarget.value !== "null" && e.currentTarget.value !== "") {
            filters[e.currentTarget.name] = e.currentTarget.value;
        } else {
            filters[e.currentTarget.name] = null;
        }

        this.refilter(filters);
    }

    handleBoolean = (e) => {
        let filters = this.state.filters;

        filters[e.currentTarget.name] = !filters[e.currentTarget.name];

        this.refilter(filters);
    }

    refilter = (filters) => {
        let filteredHappenings = [];
        let filteredOfferings = [];

        if(
            filters.venue === null
            && filters.country === null
            && filters.genre === null
            && filters.subject === null
            && filters.availability === null
            && filters.exclusive === null
            && filters.qas === false
            && filters.subtitles === false
            && filters.language === null
            && filters.cc === false
            && filters.title === ''
        ) {
            filteredHappenings = this.state.allHappenings;
            filteredOfferings = this.state.allOfferings;
        } else {

            for(const happening of this.state.allHappenings) {
                const offering = happening.get('field_offering');
                const featureFilm = offering.get('field_feature_film');
                if(featureFilm instanceof Include) {
                    if(
                        (filters.venue === null || filters.venue === happening.get('field_space').get('field_venue').get('id')) &&
                        (filters.country === null || this.matchMultiple(featureFilm.get('field_countries'), filters.country)) &&
                        (filters.genre === null || featureFilm.get('field_genre').get('id') === filters.genre) &&
                        (filters.subject === null || this.matchMultiple(featureFilm.get('field_subjects'), filters.subject)) &&
                        (filters.availability === null || this.isHappeningAvailable(happening, filters.availability)) &&
                        (filters.exclusive === null || this.isExclusive(offering, filters.exclusive)) &&
                        (filters.qas === false || this.hasQA(happening)) &&
                        (filters.subtitles === false || this.hasSubtitles(featureFilm)) &&
                        (filters.cc === false || this.hasCC(featureFilm)) &&
                        (filters.language === null || this.matchMultiple(featureFilm.get('field_languages'), filters.language)) &&
                        (filters.title === '' || filters.title === null || featureFilm.get('title')?.toLowerCase()?.includes(filters.title?.toLowerCase()) || offering.get('title').toLowerCase().includes(filters.title?.toLowerCase()))
                    ) {
                        filteredHappenings.push(happening);
                        if(!filteredOfferings.find(e => e.get('id') === offering.get('id'))) {
                            filteredOfferings.push(offering);
                        }
                    }
                } else {
                    const films = happening.get('field_offering').get('field_films');
                    let result = false;
                    for(const film of films) {
                        if(
                            (filters.venue === null || filters.venue === happening.get('field_space').get('field_venue').get('id')) &&
                            (filters.country === null || this.matchMultiple(film.get('field_countries'), filters.country)) &&
                            (filters.genre === null || film.get('field_genre').get('id') === filters.genre) &&
                            (filters.subject === null || this.matchMultiple(film.get('field_subjects'), filters.subject)) &&
                            (filters.availability === null || this.isHappeningAvailable(happening, filters.availability)) &&
                            (filters.exclusive === null || this.isExclusive(offering, filters.exclusive)) &&
                            (filters.qas === false || this.hasQA(happening)) &&
                            (filters.subtitles === false || this.hasSubtitles(film)) &&
                            (filters.cc === false || this.hasCC(film)) &&
                            (filters.language === null || this.matchMultiple(film.get('field_languages'), filters.language)) &&
                            (filters.title === '' || filters.title === null || film.get('title')?.toLowerCase()?.includes(filters.title?.toLowerCase()) || offering.get('title').toLowerCase().includes(filters.title?.toLowerCase()))
                        ) {
                            result = true;
                        }
                    }

                    if(result) {
                        filteredHappenings.push(happening);
                        if(!filteredOfferings.find(e => e.get('id') === offering.get('id'))) {
                            filteredOfferings.push(offering);
                        }
                    }
                }
            }
        }

        if(this.state.filters.sort === 'asc') {
            filteredOfferings.sort((a, b) => a.get('field_sort_title') > b.get('field_sort_title') ? 1 : -1);
        } else {
            filteredOfferings.sort((a, b) => a.get('field_sort_title') < b.get('field_sort_title') ? 1 : -1);
        }

        this.setState({
            filteredHappenings: filteredHappenings,
            filteredOfferings: filteredOfferings,
            filters: filters
        });
    }

    matchMultiple = (values, filter) => {
        return values.find(e => e instanceof Include && e.get('id') === filter);
    }

    clearFilters = () => {
        const filters = {
            venue: null,
            subject: null,
            genre: null,
            country: null,
            availability: null,
            exclusive: null,
            qas: false,
            subtitles: false,
            language: null,
            cc: false,
            title: '',
            sort: 'asc'
        };

        this.refilter(filters);
    }

    clearSearch = () => {
        let filters = this.state.filters;
        filters.title = '';
        this.refilter(filters);
    }

    getAllHappenings = async () => {
        let happenings = [];

        const params = {
            'filter[festival][path]': 'field_festival_series.id',
            'filter[festival][value]': this.props.entity.get('id'),
            'sort[title][path]': 'field_offering.field_sort_title',
            'sort[title][direction]': 'ASC',
            'sort[date][path]': 'field_date_time',
            'sort[date][direction]': 'ASC'
        };

        let next = '';
        let hasNext = true;

        let screeningResponse = await this.props.api.getEntities('happening', 'screening', params);

        happenings = happenings.concat(screeningResponse.data.data);

        if(screeningResponse.data?.links?.next?.href) {
            next = screeningResponse.data.links.next.href;
            
            while(hasNext) {
                screeningResponse = await this.props.api.getResponseFromUrl(next);

                if(screeningResponse.data?.links?.next?.href) {
                    next = screeningResponse.data.links.next.href;
                } else {
                    hasNext = false;
                }

                happenings = happenings.concat(screeningResponse.data.data);
            }
        }

        next = '';
        hasNext = true;
        params['sort[date][path]'] = 'field_start_date';
        let virtualScreeningResponse = await this.props.api.getEntities('happening', 'virtual_screening', params);

        happenings = happenings.concat(virtualScreeningResponse.data.data);

        if(virtualScreeningResponse.data?.links?.next?.href) {
            next = virtualScreeningResponse.data.links.next.href;
            
            while(hasNext) {
                virtualScreeningResponse = await this.props.api.getResponseFromUrl(next);

                if(virtualScreeningResponse.data?.links?.next?.href) {
                    next = virtualScreeningResponse.data.links.next.href;
                } else {
                    hasNext = false;
                }

                happenings = happenings.concat(virtualScreeningResponse.data.data);
            }
        }

        return happenings;

    }

    parseOfferings = (happenings) => {
        let offerings = [];

        for(const happening of happenings) {
            const offering = happening.get('field_offering');
            // Get some default values.
            const currentId = offering.get('id');
            const inPerson = (happening.get('type') === 'happening--screening');
            const index = offerings.findIndex(e => e.get('id') === currentId );

            // Add when it is not in the array.
            if(index === -1) {
                // Add exclusive flags.
                if (inPerson) {
                    offering['person'] = true;
                    offering['virtual'] = false;
                }
                else {
                    offering['person'] = false;
                    offering['virtual'] = true;
                }

                offerings.push(offering);
            } else {
                // Update exclusive flags if already present.
                if (inPerson) {
                    offerings[index]['person'] = true;
                }
                else {
                    offerings[index]['virtual'] = true;
                }
            }
        }

        offerings.sort((a, b) => (a.get('field_sort_title') > b.get('field_sort_title')) ? 1 : -1);

        return offerings;
    }

    getOfferingHappenings = (offeringId) => {
        let happenings = this.state.allHappenings;

        const result = happenings.filter(happening => happening.get('field_offering').get('id') === offeringId);

        return result;
    }

    getVenues = (happenings) => {
        let venues = [];
        for(const happening of happenings) {
            const id = happening.get('field_space').get('field_venue').get('id');
            if(venues.find(e => e.id === id)) {
                continue;
            } else {
                venues.push({
                    id: id,
                    label: happening.get('field_space').get('field_venue').get('title')
                });
            }
        }

        venues.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return venues;
    }

    getSubjects = (offerings) => {
        let subjects = [];
        let offeringSubjects = [];

        for(const offering of offerings) {
            if(offering.get('field_feature_film') instanceof Include) {
                offeringSubjects = offering.get('field_feature_film').get('field_subjects');
                for(const subject of offeringSubjects) {
                    if(subjects.find(e => e.id === subject.get('id'))) {
                        continue;
                    } else {
                        subjects.push({
                            id: subject.get('id'),
                            label: subject.get('name')
                        });
                    }
                }
            } else {
                for(const film of offering.get('field_films')) {
                    offeringSubjects = film.get('field_subjects');
                    for(const subject of offeringSubjects) {
                        if(subjects.find(e => e.id === subject.get('id'))) {
                            continue;
                        } else {
                            subjects.push({
                                id: subject.get('id'),
                                label: subject.get('name')
                            });
                        }
                    }
                }
            }
        }

        subjects.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return subjects;
    }

    getGenres = (offerings) => {
        let genres = [];
        let offeringGenre = null;

        for(const offering of offerings) {
            if(offering.get('field_feature_film') instanceof Include) {
                offeringGenre = offering.get('field_feature_film').get('field_genre');
                if(offeringGenre instanceof Include) {
                    
                    // eslint-disable-next-line
                    if(genres.find(e => e.id === offeringGenre.get('id'))) {
                        continue;
                    } else {
                        genres.push({
                            id: offeringGenre.get('id'),
                            label: offeringGenre.get('name')
                        });
                    }
                }
            } else {
                for(const film of offering.get('field_films')) {
                    offeringGenre = film.get('field_genre');
                    if(offeringGenre instanceof Include) {

                        // eslint-disable-next-line
                        if(genres.find(e => e.id === offeringGenre.get('id'))) {
                            continue;
                        } else {
                            genres.push({
                                id: offeringGenre.get('id'),
                                label: offeringGenre.get('name')
                            });
                        }
                    }
                }
            }
        }

        genres.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return genres;
    }

    getCountries = (offerings) => {
        let countries = [];
        let offeringCountries = [];

        for(const offering of offerings) {
            if(offering.get('field_feature_film') instanceof Include) {
                offeringCountries = offering.get('field_feature_film').get('field_countries');
                for(const country of offeringCountries) {
                    if(countries.find(e => e.id === country.get('id'))) {
                        continue;
                    } else {
                        countries.push({
                            id: country.get('id'),
                            label: country.get('name')
                        });
                    }
                }
            } else {
                for(const film of offering.get('field_films')) {
                    offeringCountries = film.get('field_countries');
                    for(const country of offeringCountries) {
                        if(countries.find(e => e.id === country.get('id'))) {
                            continue;
                        } else {
                            countries.push({
                                id: country.get('id'),
                                label: country.get('name')
                            });
                        }
                    }
                }
            }
        }

        countries.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return countries;
    }

    getLanguages = (offerings) => {
        let languages = [];
        let offeringLanguages = [];

        for(const offering of offerings) {
            if(offering.get('field_feature_film') instanceof Include) {
                offeringLanguages = offering.get('field_feature_film').get('field_languages');
                for(const language of offeringLanguages) {
                    if(language instanceof Include && language.get('id')) {
                        if(languages.find(e => e.id === language.get('id'))) {
                            continue;
                        } else {
                            languages.push({
                                id: language.get('id'),
                                label: language.get('name')
                            });
                        }
                    }
                }
            } else {
                for(const film of offering.get('field_films')) {
                    offeringLanguages = film.get('field_languages');
                    for(const language of offeringLanguages) {
                        if(language instanceof Include && language.get('id')) {
                            if(languages.find(e => e.id === language.get('id'))) {
                                continue;
                            } else {
                                languages.push({
                                    id: language.get('id'),
                                    label: language.get('name')
                                });
                            }
                        }
                    }
                }
            }
        }

        languages.sort((a, b) => (a.label > b.label) ? 1 : -1);

        return languages;
    }

    hasQA = (happening) => {

        let decision = false;

        if(happening.get('field_has_q_a') === true) {
            decision = true;
        }

        return decision;

    }

    hasCC = (film) => {

        let decision = false;

        if(film.get('field_has_cc') === true) {
            decision = true;
        }

        return decision;

    }

    hasSubtitles = (film) => {

        let decision = false;

        if(film.get('field_has_subtitles') === true) {
            decision = true;
        }

        return decision;

    }

    isHappeningAvailable = (happening, filter) => {
        let decision = false;

        switch(filter) {
            case 'in-theatre':
                if(happening.get('type') === 'happening--screening') {
                    decision = true;
                }
                break;
            case 'purchase':
                if(happening.get('field_sale_status') === 'on_sale') {
                    decision = true;
                }
                break;
            case 'watch-now':
                const datetime = new Date().toISOString();
                if(happening.get('type') === 'happening--virtual_screening' && happening.get('field_start_date') >= datetime && happening.get('field_end_date') <= datetime) {
                    decision = true;
                }
                break;
            default:
                decision = false;
        }

        return decision;
    }

    isExclusive = (entity, filter) => {
        const id = entity.get('id');
        const offering = this.state.allOfferings.find(e => e.get('id') === id );
        if (offering['person'] && offering['virtual'] && (filter === 'both')) {
            return true;
        }
        if (offering['person'] && !offering['virtual'] && (filter === 'person')) {
            return true;
        }
        if (!offering['person'] && offering['virtual'] && (filter === 'virtual')) {
            return true;
        }
        // No matches.
        return false;
    }

}