import React, { Component } from 'react';
import Meta from '../../meta/Meta/Meta';

import './FilmCoreInfo.css';

export default class FilmCoreInfo extends Component {

    render = () => {
        return (
            <div className="film-core-info row">
                <div className="col">
                    <Meta suppressLinks={true} alternateTheme={true} key='field_directors' field='field_directors' isInclude={true} isMulti={true} skipSort={true} entity={this.props.entity} label="Director(s)" />
                    <Meta suppressLinks={true} alternateTheme={true} key='field_genre' field='field_genre' isInclude={true} isMulti={false} entity={this.props.entity} label="Type" />
                    <Meta alternateTheme={true} key='field_run_time' field='field_run_time' isInclude={false} isMulti={false} entity={this.props.entity} label="Run Time" suffix=" minutes" />
                </div>
            </div>
        );
    }

}
